import {useEffect, useState} from "react";
import axios from "axios";

function RunPage() {
    const [error, setError] = useState(null)

    function b64_to_utf8(str) {
        return decodeURIComponent(atob(str).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    }

    const countClick = (offer_id, from = 'direct') => {
        axios.get(`https://ads-api.simplr.ru/api/v1/click?offer_id=${offer_id}`,
            {
                headers: {
                    'User-From': from
                }
            })
            .then(res => {

            })
            .catch(e => {

            })
    }

    const checkLoc = (url) => {
        let offer_id = b64_to_utf8(url).split('offer_id=')[1].split('&')[0]
        let from_param = b64_to_utf8(url).split('&aff_sub5=')[1]
        countClick(offer_id, from_param)
        const encodedURL = b64_to_utf8(url)
        window.location.replace(encodedURL)
        // axios.get('https://crediterra.app.api.simplr.ru/api/v1/check_loc').then(res => {
        //     if (res.data.result) {
        //         const encodedURL = b64_to_utf8(url)
        //         window.location.replace(encodedURL)
        //     } else {
        //         setError('wrong url')
        //     }
        // }).catch(e => {
        //     setError('wrong url')
        //     console.log(e)
        // })
    }

    useEffect(() => {
        let params = new URLSearchParams(window.location.search);
        let goValue = params.get('go');
        if (goValue) {
            checkLoc(goValue)
        } else {
            setError('wrong url')

        }
    }, [])
    return (
        <div>
            {error && <pre>wrong url</pre>}
        </div>
    );
}

export default RunPage;
