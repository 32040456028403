import {useEffect, useState} from "react";

function MainPage() {
    const [error, setError] = useState(null)
    function b64_to_utf8(str) {
        return decodeURIComponent(atob(str).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    }

    useEffect(() => {
        let params = new URLSearchParams(window.location.search);
        let goValue = params.get('go');
        try {
            const redirectURL = b64_to_utf8(goValue)
            window.location.replace(redirectURL)
        } catch (e) {
            setError(e)
            console.log(e)
        }
    }, [])
    return (
        <div>
            {error && <pre>wrong url</pre>}
        </div>
    );
}

export default MainPage;
