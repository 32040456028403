import React from 'react';
import * as ReactDOM from "react-dom/client";
import MainPage from "./MainPage";
import RunPage from "./RunPage";

import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";


const router = createBrowserRouter([
    {
        path: "/",
        element: <MainPage/>,
    },
    {
        path: "/run",
        element: <RunPage/>,
    }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <RouterProvider router={router}/>
);
